@font-face {
  font-family: 'MainBasketRun';
  src: url('./assets/static/fonts/LuckiestGuy-Regular.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'PoppinsRegular';
  src: url('./assets/static/fonts/poppins/Poppins-Regular.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PoppinsBold';
  src: url('./assets/static/fonts/poppins/Poppins-Bold.ttf');
  font-style: normal;
  font-display: swap; 
}

@font-face {
  font-family: 'OopenSans800';
  src: url('./assets/static/fonts/OpenSans-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OopenSans400';
  src: url('./assets/static/fonts/OpenSans_SemiCondensed-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


body {
    padding: 0;
    margin: 0;

  }

canvas {
    /* width: 100%; */
      background-color: #000;
      position: absolute;
      /*top: 0;*/
      /*left: 50%;*/
      /*transform: translate(-50%, 0);*/
      width: 100%;
	    height: 100%;
      background: linear-gradient(#000, #022641);
}

#fontsCont {
    font-family: 'MainBasketRun';
    font-size: 2pt;
    font-weight: bold;
    position: absolute;
}

#reportMessages {
  padding: 5px;
  color: white;
  z-index:10;
  position: absolute;
  font-size: 12pt;
  left: 10px;
  top:100px;
  width: 120px;
  opacity: 0.63;
  display:none;
}


#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background: linear-gradient(217deg, rgba(66, 5, 119, 0.8), rgb(0, 0, 0) 70.71%),
            linear-gradient(127deg, rgba(3, 3, 0, 0.8), rgba(149, 0, 62, 0.912) 70.71%),
            linear-gradient(336deg, rgba(0, 0, 0, 0.8), rgb(0, 29, 88) 70.71%);
  align-items: center;
  z-index: 10;
}

#preloader .preloader-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30%; 
  bottom: 10%; 
  left: 0;
  right: 0;
  margin: auto;
  min-height: 40%;
}

#preloader .preloader-image {
  align-self: flex-start; 
  width: 300px;
  height: 200px;
}

#preloader .progress {
  align-self: flex-end;
  margin-top: auto;
  width: 100%;
  max-height: 100px;
  font-family: PoppinsRegular;
}

#preloader .progress p{
  padding-top: 15px;
  display: inline-block; 
  color: white;
  font-size: 14px;
  margin-left: 10px;
  text-align: center;
}

#preloader .loader {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 50px;
	vertical-align: middle;
}

#preloader .loader-quart {
	border-radius: 50px;
	border: 6px solid rgba(255,255,255,0.3);
}

#preloader .loader-quart:after {
	content: '';
	position: absolute;
	top: -6px; 
	left: -6px;
	bottom: -6px;
	right: -6px;
	border-radius: 50px;
	border: 6px solid transparent;
	border-top-color: #fff;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0%{ -webkit-transform: rotate(0deg); tranform: rotate(0deg);}
100%{ -webkit-transform: rotate(360deg); tranform: rotate(360deg);}
}

@keyframes spin {
0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg);}
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg);}
}

.popup-overlay{
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #5D3EBC;
  background-image: url('./assets/dom/popups/bg-overlay.jpeg');
  background-repeat: repeat-x repeat-y; 
  background-position: top center;
}

.popup {
  display: flex;
  font-family: PoppinsRegular;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  flex-direction: column;
  justify-content: center;
  color: white;
  width: 100%;
  min-width: 270px;
  max-width: 360px;
  /* background-color: rgba(0, 0, 0, 0.9); */
}

.finish-game-prize .popup {
  height: auto;
  flex-shrink: 0;
  border-radius: 0;


}

.popup-content {
  font-family: PoppinsRegular;
  flex-grow: 1;
  margin-top: 56px;
  margin-left: 20px;;
  margin-right: 20px;;
  /* background-color: rgba(0, 0, 100, 0.6); */
  padding: 0;
  background-image: url('./assets/dom/popups/prize-popup-bg.svg');
  background-size: 100%  100%; 
  background-repeat: no-repeat;
  width: auto;
  height: auto;
  /* border-image-slice: 30 30; */
}

.finish-game .popup-content {
  background-image: url('./assets/dom/popups/popup-bg.svg');  
}

.inner-popup-content {
  padding-left: 30px;
  padding-right: 30px;
}

.popup-header {
  /* display: flex; */
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 90%;
  background-image: url('./assets/dom/popups/prize-popup-scores.svg');
  background-size: 100% 100%; 
  height: 100px;
  text-align: left;
  /* justify-content: space-between; */
  /* align-items: center; */
  text-transform: uppercase;
  z-index: 3;
}

.popup-header .getir-logo{
  text-align: center;
  width: 100%;;
  margin-top: -22px;
}

.popup-header h2 {
  font-family: MainBasketRun;
  text-align: left;
  flex-grow: 1;
  /* width: calc(100% - 20px); */
  margin: 12px 24px 12px 24px;
  font-size: 36px;
  font-weight: 900;
  line-height: 60px;
}

.popup-header .close-btn{
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 32px;
  cursor: pointer;
}

.popup h2 {
  font-family: MainBasketRun;
  text-align: center;
  font-size: 36px;
  font-weight: 900;
  line-height: 45px;
}

.popup-header h2 {
  font-size: 36px;
  line-height: 20px;
}


.popup .popup-header .scores{
  color: var(--White, #ffffff);
  text-align: center;
  text-shadow: 2px 2px 0px #303297;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #6642BC;
  font-family: "MainBasketRun";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 112% */
}

.finish-game .popup .popup-header .scores-value{
  color: var(--White, #F14D4D);
  font-size: 40px;
}

.finish-game .popup .popup-header .scores{
  font-size: 20px;
}
.finish-game-prize .popup .popup-header .scores{
  font-size: 36px;
}

.rize-finish-game .popup .popup-header .scores-value{
  font-size: 50px;
}



.popup .inner-popup-content {
  text-align: center;
  /* padding: 15%; */
}

.popup .inner-popup-content img {
  /* Styles for the image */
  max-width: 100%; /* Set maximum width to 100% of the container */
  max-height: 100%; /* Set maximum height to 100% of the container */
  width: auto; /* Ensure the width adjusts to maintain aspect ratio */
  height: auto; /* Ensure the height adjusts to maintain aspect ratio */
  display: block; /* Ensure proper rendering as block element */
  margin: 0 auto; /* Optional: Center the image horizontally */
}

.popup .inner-popup-content {
  color: var(--neutral-neutral-0000, var(--White, #FFF));
  font-family: "OopenSans800";
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 108.333% */
  
}

.popup .inner-popup-content h2 {
  padding-top: 25px; 
  margin-top: 30px;
  margin-bottom: 0;

  color: var(--neutral-neutral-0000, var(--White, #FFF));
  text-align: center;
  text-shadow: 2px 2px 0px #303297;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #6642BC;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 111.111% */

}



.popup button {
  border-radius: 24px;
  border: 1px solid #D4AD09;

  background: var(--brand-yelow-0400, #FFD10D);
  box-shadow: 1px 2px 0px 0px #2D2F95;

  color: var(--brand-purple-0400, #5D3EBC);
  text-align: center;
  font-family: "OopenSans800";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 110% */
  letter-spacing: -1px;
  text-transform: uppercase;
  padding: 6px 15px 6px 15px;
  cursor: pointer;
}

.popup .inner-popup-content button {
  width: 95%;
  margin: 10px;
  min-width: 170px
  /* display: block; */
}


.finish-game-prize .inner-popup-content h3{
  color: var(--neutral-neutral-0000, var(--White, #FFF));
  text-align: center;
  font-family: "OopenSans800";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; 
  padding: 0;
  margin-top:0px;
  margin-bottom:10px;
}

.finish-game-prize .inner-popup-content h4{
  color: var(--neutral-neutral-0000, var(--White, #FFF));
  text-align: center;
  font-family: "OopenSans800";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 108.333% */
  margin: 0;
  padding: 0;
}

.finish-game-prize .inner-popup-content h5{
  color: var(--neutral-neutral-0000, var(--White, #FFF));
  text-align: center;
  font-family: "OopenSans400";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.finish-game-prize .inner-popup-content h4 {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 14px;
  min-width: 220px;

}

.finish-game-prize textarea.code-for-copy{
  width: 180px;
  height: 32px;;
  display: inline-block;
  border: none;
  outline: none;
  resize: none;
  background-color: transparent;
  font-family: inherit;
  color:white;
  padding-bottom: -10px;
  margin-bottom: -8px;

  color: var(--neutral-neutral-0000, var(--White, #FFF));
  text-align: center;
  font-family: "OopenSans800";
  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 108.333% */
  /* border: 1px solid red; */
  margin-left: -22px;
  margin-right: -22px;
  
}

.finish-game-prize .code-for-copy{
  /* width: 180px; */
  height: 32px;;
  display: inline-block;
  border: none;
  outline: none;
  resize: none;
  background-color: transparent;
  font-family: inherit;
  color:white;
  padding-bottom: -10px;
  margin-bottom: -8px;

  color: var(--neutral-neutral-0000, var(--White, #FFF));
  text-align: center;
  font-family: "OopenSans800";
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 108.333% */
  /* border: 1px solid red; */
  /* margin-left: -22px; */
  /* margin-right: -22px; */
  
}



.finish-game-prize .play-again-btn{
  border-radius: 24px;
  border: 1px solid var(--brand-yelow-0400, #FFD10D);
  background-color: #5D3EBC;
  color: #FFD10D;

}

.finish-game .inner-popup-content h4{
  padding-top: 0;
  padding-top: 0;
  margin-top: 15px;
  margin-bottom: 20px;
  
}

.finish-game .inner-popup-content button{
  margin-bottom: 60px;
  
}




.tutorial-overlay{
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
}

.tutorial {
  display: flex;
  font-family: PoppinsRegular;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 540px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  
  max-width: 353px;
  margin: auto;
  padding: 0px;
  color: white;
  border-radius: 32px;

  /* border: 4px solid #888; */
  background-color: #888;
}

.tutorial-content {
  font-family: PoppinsRegular;
  flex-grow: 1;
  margin-top: 77px;
  overflow-y: auto;
  background: #292929;
  padding: 10px;
  border-radius: 0 0 32px 32px;
}


#tutorial1en .tutorial {
  background-color: #2b75ee;
}

#tutorial2en .tutorial{
  background-color: #5D3EBC;
}

#tutorial3en .tutorial {
  background-color: #FF3B47;
}

#tutorial4en .tutorial {
  background-color: #9C42FF;
}

#tutorial1fr .tutorial {
  background-color: #2B75ED;
}

#tutorial2fr .tutorial{
  background-color: #5D3EBC;
}

#tutorial3fr .tutorial {
  background-color: #FF3B47;
}

#tutorial4fr .tutorial {
  background-color: #9C42FF;
}

.tutorial-header {
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background:none;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  z-index: 3;
}

.tutorial-header h2 {
  font-family: MainBasketRun;
  text-align: left;

  flex-grow: 1;
  width: calc(100% - 20px);
  margin: 12px 0 12px 25px;

  font-size: 36px;
  font-weight: 900;
  line-height: 60px;

}

.tutorial-header .close-btn{
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 32px;
  cursor: pointer;
}

.tutorial-header .close-btn img{
  height: 32px;
  width: 32px;
  margin: 10px;
}


.tutorial-content .inner-content{
  margin: 6px;
}

.tutorial-content .block {
  margin-bottom: 23px;
}

.tutorial-content h4 {
  padding-top: 2px;
  font-family: 'PoppinsBold';
  font-size: 20px;
  margin: 4px 0 8px 6px;
}

.tutorial-content h6 {
  font-family: 'PoppinsBold';
  font-size: 16px;
  font-weight: 900;
}

.tutorial-content p {
  font-size: 14px;
  font-weight: 900;
  line-height: 18px;
  margin-left: 6px;
}

.tutorial-content .product-line {
  padding-top: 2px;
  padding-bottom: 0px;
  margin-bottom: 0;  
}

.tutorial  .block .items {
  margin-top: -20px;  
}


.product-line {
  margin-left: 0px;
  margin-right: 0px;

}

.tutorial  .block .top-line {
  margin-left: 4px;
  /* text-align: center; */
  justify-content: space-between;
  display: flex;
}

#tutorial1en  .block .top-line {
  margin-left: 4px;
  /* text-align: center; */
  justify-content: space-between;
  display: flex;
}



#tutorial1en  .top-line img{
  /* padding-right: 10px; */
}

#tutorial2en  .block .top-line {
  margin-left: 2px;
}

#tutorial3en  .block .top-line {
  margin-left: 0px;
  margin-bottom: 26px;
}


#tutorial4en .block .top-line {
  margin-left: 0px;
}


#tutorial1fr  .block .top-line {
  /* margin-left: 0px; */
}

#tutorial1fr  .top-line img{
/*   padding-right: 10px; */
}

#tutorial2fr  .block .top-line {
  /* margin-left: 2px; */
  margin-bottom: 26px;
}

#tutorial2fr  .top-line img{
  /* padding-right: 12px; */
}

#tutorial3fr  .block .top-line {
  margin-left: 0px;
}

#tutorial4fr .block .top-line {
  margin-left: 0px;
}



.cart-control {
  display: flex;
  height: 200px;
  margin-bottom: 20px;
  background:  url('./assets/cart.png') center no-repeat;
}

.cart-control img {
  display: inline-block;
  text-align: center;
  margin: auto;
  padding-top: 90px;
}

.cart-control  img:nth-of-type(2) {
  padding-top: 110px;
}

.pause-game .tutorial{
  background-color: #ffffff;
  height: 383px;
  width: 90%;
  max-width: 400px;
  
}

.pause-game .tutorial h2{
  color: #5D3EBC;
  font-family: "OopenSans800";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  text-transform: uppercase;
}

.pause-game .tutorial h2{
  color: #5D3EBC;
}

.pause-game .tutorial-content{
  background-color: #5D3EBC;
  text-align: left;
}

.pause-game .tutorial .inner-tutorial-content h4{
  color: var(--neutral-neutral-0000, var(--White, #FFF));
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
  padding-bottom: 10px;
}

.pause-game .tutorial .inner-tutorial-content p{
  color: var(--neutral-neutral-0000, var(--White, #FFF));
  font-family: "OopenSans400";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 0;
  margin-top: 12px;
  padding-bottom: 15px;

}

.pause-game .tutorial .inner-tutorial-content button{
  display: flex;
  width: 256px;
  height: 56px;
  /* padding: 22px 40px; */
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  margin: 20px 0;
  color: #5D3EBC;
  text-align: center;
  font-family: "OopenSans800";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 110% */
  letter-spacing: -1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0;


}


.pause-game .tutorial .inner-tutorial-content .get-prize-btn{
  background: #FFD10D;
}

.pause-game .tutorial .inner-tutorial-content .get-prize-btn{
  background: #e1ba0b;
  border-radius: 0;
}

.pause-game .tutorial .inner-tutorial-content .keep-playing-btn{
  background: #ccc;
  border-radius: 0;
}


#finish-game-en {
  display: none;
}

#finish-game-prize-en {
  display: none;
}

#pause-game-en {
  display: none;
}

#finish-game-fr {
  display: none;
}

#finish-game-prize-fr {
  display: none;
}

#pause-game-fr {
  display: none;
}


h6 {
  margin: 0 0 5px;
}

p {
  margin: 0;
}
