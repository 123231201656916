@font-face {
  font-family: MainBasketRun;
  src: url("LuckiestGuy-Regular.46f2bef8.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PoppinsRegular;
  src: url("Poppins-Regular.49aef68e.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PoppinsBold;
  src: url("Poppins-Bold.48c34fc6.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OopenSans800;
  src: url("OpenSans-ExtraBold.86a48209.ttf");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OopenSans400;
  src: url("OpenSans_SemiCondensed-Regular.0d45e691.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
}

canvas {
  background: linear-gradient(#000, #022641);
  width: 100%;
  height: 100%;
  position: absolute;
}

#fontsCont {
  font-family: MainBasketRun;
  font-size: 2pt;
  font-weight: bold;
  position: absolute;
}

#reportMessages {
  color: #fff;
  z-index: 10;
  opacity: .63;
  width: 120px;
  padding: 5px;
  font-size: 12pt;
  display: none;
  position: absolute;
  top: 100px;
  left: 10px;
}

#preloader {
  text-align: center;
  z-index: 10;
  background: linear-gradient(217deg, #420577cc, #000 70.71%), linear-gradient(127deg, #030300cc, #95003ee9 70.71%), linear-gradient(336deg, #000c, #001d58 70.71%);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#preloader .preloader-container {
  flex-direction: column;
  min-height: 40%;
  margin: auto;
  display: flex;
  position: absolute;
  inset: 30% 0 10%;
}

#preloader .preloader-image {
  align-self: flex-start;
  width: 300px;
  height: 200px;
}

#preloader .progress {
  align-self: flex-end;
  width: 100%;
  max-height: 100px;
  margin-top: auto;
  font-family: PoppinsRegular;
}

#preloader .progress p {
  color: #fff;
  text-align: center;
  margin-left: 10px;
  padding-top: 15px;
  font-size: 14px;
  display: inline-block;
}

#preloader .loader {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
}

#preloader .loader-quart {
  border: 6px solid #ffffff4d;
  border-radius: 50px;
}

#preloader .loader-quart:after {
  content: "";
  border: 6px solid #0000;
  border-top-color: #fff;
  border-radius: 50px;
  animation: 1s linear infinite spin;
  position: absolute;
  inset: -6px;
}

@-webkit-keyframes spin {
  0% {
    tranform: rotate(0deg);
    -webkit-transform: rotate(0);
  }

  100% {
    tranform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.popup-overlay {
  z-index: 2;
  background-color: #5d3ebc;
  background-image: url("bg-overlay.c3545bef.jpeg");
  background-repeat: repeat-x repeat-y;
  background-position: top;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

.popup {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 270px;
  max-width: 360px;
  font-family: PoppinsRegular;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.finish-game-prize .popup {
  border-radius: 0;
  flex-shrink: 0;
  height: auto;
}

.popup-content {
  background-image: url("prize-popup-bg.eea3b555.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-grow: 1;
  width: auto;
  height: auto;
  margin-top: 56px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  font-family: PoppinsRegular;
}

.finish-game .popup-content {
  background-image: url("popup-bg.0f5da5d2.svg");
}

.inner-popup-content {
  padding-left: 30px;
  padding-right: 30px;
}

.popup-header {
  text-align: left;
  text-transform: uppercase;
  z-index: 3;
  background-image: url("prize-popup-scores.87faef6b.svg");
  background-size: 100% 100%;
  max-width: 90%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.popup-header .getir-logo {
  text-align: center;
  width: 100%;
  margin-top: -22px;
}

.popup-header h2 {
  text-align: left;
  flex-grow: 1;
  margin: 12px 24px;
  font-family: MainBasketRun;
  font-size: 36px;
  font-weight: 900;
  line-height: 60px;
}

.popup-header .close-btn {
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  height: 32px;
  margin: 0;
  padding: 0;
  display: flex;
}

.popup h2 {
  text-align: center;
  font-family: MainBasketRun;
  font-size: 36px;
  font-weight: 900;
  line-height: 45px;
}

.popup-header h2 {
  font-size: 36px;
  line-height: 20px;
}

.popup .popup-header .scores {
  color: var(--White, #fff);
  text-align: center;
  text-shadow: 2px 2px #303297;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #6642bc;
  font-family: MainBasketRun;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.finish-game .popup .popup-header .scores-value {
  color: var(--White, #f14d4d);
  font-size: 40px;
}

.finish-game .popup .popup-header .scores {
  font-size: 20px;
}

.finish-game-prize .popup .popup-header .scores {
  font-size: 36px;
}

.rize-finish-game .popup .popup-header .scores-value {
  font-size: 50px;
}

.popup .inner-popup-content {
  text-align: center;
}

.popup .inner-popup-content img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}

.popup .inner-popup-content {
  color: var(--neutral-neutral-0000, var(--White, #fff));
  text-align: center;
  font-family: OopenSans800;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
}

.popup .inner-popup-content h2 {
  color: var(--neutral-neutral-0000, var(--White, #fff));
  text-align: center;
  text-shadow: 2px 2px #303297;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #6642bc;
  margin-top: 30px;
  margin-bottom: 0;
  padding-top: 25px;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.popup button {
  background: var(--brand-yelow-0400, #ffd10d);
  color: var(--brand-purple-0400, #5d3ebc);
  text-align: center;
  letter-spacing: -1px;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #d4ad09;
  border-radius: 24px;
  padding: 6px 15px;
  font-family: OopenSans800;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  box-shadow: 1px 2px #2d2f95;
}

.popup .inner-popup-content button {
  width: 95%;
  min-width: 170px;
  margin: 10px;
}

.finish-game-prize .inner-popup-content h3 {
  color: var(--neutral-neutral-0000, var(--White, #fff));
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0;
  font-family: OopenSans800;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
}

.finish-game-prize .inner-popup-content h4 {
  color: var(--neutral-neutral-0000, var(--White, #fff));
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: OopenSans800;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
}

.finish-game-prize .inner-popup-content h5 {
  color: var(--neutral-neutral-0000, var(--White, #fff));
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: OopenSans400;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.finish-game-prize .inner-popup-content h4 {
  min-width: 220px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.finish-game-prize textarea.code-for-copy {
  resize: none;
  color: var(--neutral-neutral-0000, var(--White, #fff));
  text-align: center;
  background-color: #0000;
  border: none;
  outline: none;
  width: 180px;
  height: 32px;
  margin-bottom: -8px;
  margin-left: -22px;
  margin-right: -22px;
  padding-bottom: -10px;
  font-family: OopenSans800;
  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
  display: inline-block;
}

.finish-game-prize .code-for-copy {
  resize: none;
  color: var(--neutral-neutral-0000, var(--White, #fff));
  text-align: center;
  background-color: #0000;
  border: none;
  outline: none;
  height: 32px;
  margin-bottom: -8px;
  padding-bottom: -10px;
  font-family: OopenSans800;
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
  display: inline-block;
}

.finish-game-prize .play-again-btn {
  border: 1px solid var(--brand-yelow-0400, #ffd10d);
  color: #ffd10d;
  background-color: #5d3ebc;
  border-radius: 24px;
}

.finish-game .inner-popup-content h4 {
  margin-top: 15px;
  margin-bottom: 20px;
  padding-top: 0;
}

.finish-game .inner-popup-content button {
  margin-bottom: 60px;
}

.tutorial-overlay {
  z-index: 2;
  background-color: #000c;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}

.tutorial {
  color: #fff;
  background-color: #888;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 353px;
  height: 540px;
  margin: auto;
  padding: 0;
  font-family: PoppinsRegular;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tutorial-content {
  background: #292929;
  border-radius: 0 0 32px 32px;
  flex-grow: 1;
  margin-top: 77px;
  padding: 10px;
  font-family: PoppinsRegular;
  overflow-y: auto;
}

#tutorial1en .tutorial {
  background-color: #2b75ee;
}

#tutorial2en .tutorial {
  background-color: #5d3ebc;
}

#tutorial3en .tutorial {
  background-color: #ff3b47;
}

#tutorial4en .tutorial {
  background-color: #9c42ff;
}

#tutorial1fr .tutorial {
  background-color: #2b75ed;
}

#tutorial2fr .tutorial {
  background-color: #5d3ebc;
}

#tutorial3fr .tutorial {
  background-color: #ff3b47;
}

#tutorial4fr .tutorial {
  background-color: #9c42ff;
}

.tutorial-header {
  text-align: left;
  text-transform: uppercase;
  z-index: 3;
  background: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tutorial-header h2 {
  text-align: left;
  flex-grow: 1;
  width: calc(100% - 20px);
  margin: 12px 0 12px 25px;
  font-family: MainBasketRun;
  font-size: 36px;
  font-weight: 900;
  line-height: 60px;
}

.tutorial-header .close-btn {
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  height: 32px;
  margin: 0;
  padding: 0;
  display: flex;
}

.tutorial-header .close-btn img {
  width: 32px;
  height: 32px;
  margin: 10px;
}

.tutorial-content .inner-content {
  margin: 6px;
}

.tutorial-content .block {
  margin-bottom: 23px;
}

.tutorial-content h4 {
  margin: 4px 0 8px 6px;
  padding-top: 2px;
  font-family: PoppinsBold;
  font-size: 20px;
}

.tutorial-content h6 {
  font-family: PoppinsBold;
  font-size: 16px;
  font-weight: 900;
}

.tutorial-content p {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 900;
  line-height: 18px;
}

.tutorial-content .product-line {
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 0;
}

.tutorial .block .items {
  margin-top: -20px;
}

.product-line {
  margin-left: 0;
  margin-right: 0;
}

.tutorial .block .top-line, #tutorial1en .block .top-line {
  justify-content: space-between;
  margin-left: 4px;
  display: flex;
}

#tutorial2en .block .top-line {
  margin-left: 2px;
}

#tutorial3en .block .top-line {
  margin-bottom: 26px;
  margin-left: 0;
}

#tutorial4en .block .top-line {
  margin-left: 0;
}

#tutorial2fr .block .top-line {
  margin-bottom: 26px;
}

#tutorial3fr .block .top-line, #tutorial4fr .block .top-line {
  margin-left: 0;
}

.cart-control {
  background: url("cart.6b9fd3c3.png") center no-repeat;
  height: 200px;
  margin-bottom: 20px;
  display: flex;
}

.cart-control img {
  text-align: center;
  margin: auto;
  padding-top: 90px;
  display: inline-block;
}

.cart-control img:nth-of-type(2) {
  padding-top: 110px;
}

.pause-game .tutorial {
  background-color: #fff;
  width: 90%;
  max-width: 400px;
  height: 383px;
}

.pause-game .tutorial h2 {
  color: #5d3ebc;
  letter-spacing: -1px;
  text-transform: uppercase;
  font-family: OopenSans800;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 52px;
}

.pause-game .tutorial-content {
  text-align: left;
  background-color: #5d3ebc;
}

.pause-game .tutorial .inner-tutorial-content h4 {
  color: var(--neutral-neutral-0000, var(--White, #fff));
  padding-bottom: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
}

.pause-game .tutorial .inner-tutorial-content p {
  color: var(--neutral-neutral-0000, var(--White, #fff));
  margin-top: 12px;
  margin-left: 0;
  padding-bottom: 15px;
  font-family: OopenSans400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pause-game .tutorial .inner-tutorial-content button {
  color: #5d3ebc;
  text-align: center;
  letter-spacing: -1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 256px;
  height: 56px;
  margin: 20px 0;
  font-family: OopenSans800;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  display: flex;
}

.pause-game .tutorial .inner-tutorial-content .get-prize-btn {
  background: #e1ba0b;
  border-radius: 0;
}

.pause-game .tutorial .inner-tutorial-content .keep-playing-btn {
  background: #ccc;
  border-radius: 0;
}

#finish-game-en, #finish-game-prize-en, #pause-game-en, #finish-game-fr, #finish-game-prize-fr, #pause-game-fr {
  display: none;
}

h6 {
  margin: 0 0 5px;
}

p {
  margin: 0;
}

/*# sourceMappingURL=index.228a8dd6.css.map */
